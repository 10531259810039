import React, { useState } from "react";
import MyChatBox from "./MyChatBox";
import chat from "./assets/images/call-01.png";

function App() {
  const [isOpen, setOpen] = useState(false);

  return isOpen ? (
    <div
      className="main-wrapper"
    >
      <MyChatBox
        handleClose={() => {
          setOpen(false);
        }}
      />
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        // height: "85vh",
        alignItems: "end",
        // padding: "1rem 2rem",
        position: "fixed",
        bottom: "10px",
        right: "20px",
      }}
    >
      <img
        onClick={() => {
          setOpen(true);
        }}
        className="avatar-chat pointer"
        src={chat}
        alt=""
      />
    </div>
  );
}

export default App;
