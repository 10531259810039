import React, { useEffect, useRef, useState } from "react";
import "../src/assets/css/chatbot.css";
import avatar from "./assets/images/avatar.png";
import hand from "./assets/images/hand.png";
import ellipsis from "./assets/images/ellipsis.png";
import arrow from "./assets/images/arrow.png";
import patientExpressLogo from "./assets/images/patient-express-logo.png";
import pxLogo from "./assets/images/px-logo.png";
import send from "./assets/images/send.png";

const MyChatBox = ({ handleClose }) => {
  const [message, setMessage] = useState("");
  const [setRequest] = useState("");
  const messageEndRef = useRef(null);

  const formatAMPM = () => {
    var date = new Date();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  const [chatData, setChatData] = useState([
    {
      message: "Welcome to PatientXpress Support!",
      time: formatAMPM(),
      isBot: true,
      isUser: false,
      isRequest: false,
      isResponse: true,
      isUserHeading: false,
      isBtn: false,
    },
    {
      message: "How can we help you? Please click one of the below options!",
      time: formatAMPM(),
      isBot: true,
      isUser: false,
      isRequest: false,
      isResponse: true,
      isUserHeading: false,
      isBtn: false,
    },
    {
      message: "Sign Up",
      time: formatAMPM(),
      isBot: false,
      isUser: false,
      isRequest: false,
      isResponse: true,
      isUserHeading: false,
      isBtn: true,
      reqType: "signup",
    },
    {
      message: "Request For Demo",
      time: formatAMPM(),
      isBot: false,
      isUser: false,
      isRequest: false,
      isResponse: true,
      isUserHeading: false,
      isBtn: true,
      reqType: "reqDemo",
    },
    {
      message: "Technical Support",
      time: formatAMPM(),
      isBot: false,
      isUser: false,
      isRequest: false,
      isResponse: true,
      isUserHeading: false,
      isBtn: true,
      reqType: "support",
    },
  ]);
  // const scrollToBottom = () => {
  //   setTimeout(() => {
  //     const element = document.getElementById("message-container");
  //     element.scrollTo(0, document.body.scrollHeight);
  //   }, 1000);
  // };
  // console.log(chatData)
  useEffect(() => {
    setTimeout(() => {
      messageEndRef.current?.scrollIntoView();
    }, 200);
  }, [chatData]);

  const handleSendMessage = () => {
    if (message) {
      setChatData([
        ...chatData,
        {
          message,
          time: formatAMPM(),
          isBot: false,
          isUser: true,
          isRequest: false,
          isResponse: false,
          isUserHeading: false,
          isBtn: false,
        },
      ]);
      setMessage("");
      // scrollToBottom();
    }
  };

  const handleSendRequest = (requestType) => {
      // if (request) {
    //   setChatData([
    //     ...chatData,
    //     {
    //       request,
    //       time: formatAMPM(),
    //     },
    //   ]);
    //   setRequest("");
    //   scrollToBottom();
    // }
    if (requestType && requestType === "signup") {
      // setChatData([
        //   ...chatData,
        
        // ]);
        setChatData([
          ...chatData,
          {
            message: "Signup PatientXpress",
            time: formatAMPM(),
            isBot: false,
            isUser: true,
            isRequest: true,
            isResponse: false,
            isUserHeading: true,
            isBtn: false,
          },
          {
            time: formatAMPM(),
            isBot: true,
            isUser: false,
            isTyping: true,
            isRequest: false,
            isResponse: true,
            isUserHeading: false,
            isBtn: false,
          },
        ]);
        messageEndRef.current?.scrollIntoView();
        
        setTimeout(() => {
        setChatData(prev=> [
          ...prev.filter(item=> !item.isTyping),
          {
            message:
              "I can help you with that and thanks for thinking about PatientXpress for your office(s). Here is the list of product bundles we offer. 1) Texting/Reminder 2) Paperless Forms 3) Online Appt booking 4) Quick Insurance re-verification 5) SmartCallerID/Call Tracking. 6) Contactless Payments 7 ) 24/ 7 Website Chatbot with Check-in functionality",
            time: formatAMPM(),
            isBot: true,
            isUser: false,
            isRequest: true,
            isResponse: false,
            isUserHeading: false,
            isBtn: false,
          },
          {
            message:
              "Full package with all the features is offered at USD 299/month, which we believe is the best value in industry. Also if you have multiple offices, we do offer a 15% discount.",
            time: formatAMPM(),
            isBot: true,
            isUser: false,
            isRequest: true,
            isResponse: false,
            isUserHeading: false,
            isBtn: false,
          },
          {
            message:
              "What services are you specifically looking for? If you are looking for all, type ALL or type the services you would like to sign up using a comma.",
            time: formatAMPM(),
            isBot: true,
            isUser: false,
            isRequest: true,
            isResponse: false,
            isUserHeading: false,
            isBtn: false,
          },
        ]);
        setMessage("");
        setRequest("");
        // scrollToBottom();
      }, 5000);
      }
  };

  return (
    <div className="main-box">
      <div className="header">
        <img className="header-avatar" src={avatar} alt="" />
        <p className="header-text">Hi there!</p>
        <img className="header-avatar-hand" src={hand} alt="" />
        <img className="header-avatar-ellipsis" src={ellipsis} alt="" />
        <img
          onClick={handleClose}
          className="header-avatar-arrow pointer"
          src={arrow}
          alt=""
        />
      </div>
      <div className="header-online">
        <p className="header-online-text">
          {/* <span className="header-online-dot">●</span>We are online */}
        </p>
      </div>
      <div id="message-container" className="message-container">
        {/* <p className="message-text-time flex-left">3:01 pm</p>
        <div className="message-left">
          <p className="message-text">Welcome to PatientXpress Support!</p>
        </div>
        <p className="message-text-time flex-left">3:01 pm</p>
        <div className="message-left">
          <p className="message-text">
            How can we help you? Please click one of the below options.
          </p>
        </div> */}

        {/* <div className="message-right">
          <button
            className="btn pointer"
            onClick={(e) => handleSendRequest("signup")}
          >
            Sign Up info
          </button>
        </div>

        <div className="message-right">
          <button className="btn pointer" onClick={handleSendRequest}>
            Request For Demo
          </button>
        </div>

        <div className="message-right">
          <button className="btn pointer" onClick={handleSendRequest}>
            Technical Support
          </button>
        </div> */}
        {/* {messagesData.map((item, index) => {
          return (
            <div key={index}>
              <p className="message-text-time flex-right">{item.time}</p>
              <div className="message-right">
                <p className="message-text">{item.message}</p>
              </div>
            </div>
          );
        })}
        {requestData.map((item, index) => {
          return (
            <div key={index}>
              <p className="message-text-time flex-left">{item.time}</p>
              <div className="message-left">
                <p className="message-text">{item.request}</p>
              </div>
            </div>
          );
        })} */}
        {chatData.map((item, index) => {
          return (
            <div key={index}>
              {!item.isBtn && !item.isTyping && (
                <p
                  className={`message-text-time flex-${
                    item.isUser ? "right" : item.isBot ? "left" : ""
                  }`}
                >
                  {item.time}
                </p>
              )}
              <div
                className={`message-${
                  item.isUser ? "right" : item.isBot ? "left" : ""
                }`}
              >
                {!item.isBtn && (
                  <p
                    className={`message-text ${
                      item.isUser && item.isUserHeading && "message-heading"
                    }`}
                  >
                    {item.isTyping ? <div class="dot-pulse"></div> : item.message}
                  </p>
                )}
                {item.isBtn && (
                  <div className="btn-center">
                    <button
                      className="button"
                      onClick={(e) => handleSendRequest(item.reqType)}
                    >
                      <span class="btn-descripeion">{item.message}</span>
                      {/* <div class="btn-icon icon"> */}
                        {/* {item.reqType == "signup" && (
                          <img src={info} alt="Icon"></img>
                        )}
                        {item.reqType == "reqDemo" && (
                          <img src={reqDemo} alt="Icon"></img>
                        )}
                        {item.reqType == "support" && (
                          <img src={support} alt="Icon"></img>
                        )}
                        <i class="">{info}</i> */}
                      {/* </div> */}
                    </button>
                  </div>
                )}
              </div>
              <div ref={messageEndRef} />
            </div>
          );
        })}
      </div>
      <div className="message-divider"></div>
      <div className="main-message">
        <div className="message-input-container">
          <form
            style={{ width: "100%" }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSendMessage();
            }}
          >
            <input
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              className="message-input"
              type="textarea"
              placeholder="Enter your message..."
            />
          </form>
          {/* <img className="emoji-icon" src={smile} alt="" /> */}
        </div>
        <div className="message-send-container">
          <img
            onClick={handleSendMessage}
            className="message-send-icon pointer"
            src={send}
            alt=""
          />
        </div>
      </div>
      <div className="footer">
        <p className="footer-text">POWERED BY</p>
        <img className="footer-avatar-px" src={pxLogo} alt="" />
        <img
          className="footer-avatar-patient"
          src={patientExpressLogo}
          alt=""
        />
      </div>
    </div>
  );
};

export default MyChatBox;
